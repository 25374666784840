import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import IndexPage from '../templates/home.js';
import { colors } from '../styles';
export const _frontmatter = {
  "title": "Home"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const VideoBanner = makeShortcode("VideoBanner");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Button = makeShortcode("Button");
const Section = makeShortcode("Section");
const Globe = makeShortcode("Globe");
const Container = makeShortcode("Container");
const ScrollSpy = makeShortcode("ScrollSpy");
const Counter = makeShortcode("Counter");
const SplitSection = makeShortcode("SplitSection");
const Blog = makeShortcode("Blog");
const Contact = makeShortcode("Contact");
const layoutProps = {
  _frontmatter
};
const MDXLayout = IndexPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <VideoBanner color='#fff' src='/CPR55751.mp4' poster='/videoposter.jpg' mdxType="VideoBanner"><Row mdxType="Row"><Column md={70} mdxType="Column">
          <h1>{`Your Partner in Solar Energy`}</h1>
          <Button to="/explore-solar" mdxType="Button">Learn More</Button>
        </Column></Row></VideoBanner>
    <Section theme='dark' mdxType="Section">
      <div style={{
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)'
      }}><Globe mdxType="Globe" /></div>
      <Container mdxType="Container"><Row mdxType="Row"><Column sm={60} mdxType="Column">
            <h2>
              <ScrollSpy mdxType="ScrollSpy">Why You Should Choose SEP</ScrollSpy>
            </h2>
            <Row mdxType="Row">
              <Column sm={85} mdxType="Column">
                <Row mdxType="Row">
                  <Column xs={50} mdxType="Column">
                    <Counter digit={25} textAfter='K' mdxType="Counter">
                      <p>{`Panels Installed in 2021`}</p>
                    </Counter>
                  </Column>
                  <Column xs={50} mdxType="Column">
                    <Counter digit={13} mdxType="Counter">
                      <p>{`States We Service In`}</p>
                    </Counter>
                  </Column>
                  <Column xs={50} mdxType="Column">
                    <Counter textAfter='MW' digit={33} mdxType="Counter">
                      <p>{`Total Solar Capacity`}</p>
                    </Counter>
                  </Column>
                  <Column xs={50} mdxType="Column">
                    <Counter startDigit={1000} digit={1200} mdxType="Counter">
                      <p>{`Sales Consultants in 2021`}</p>
                    </Counter>
                  </Column></Row>
              </Column>
            </Row>
          </Column></Row></Container></Section>
    <SplitSection img='img1' backgroundText='About' title='Who We Are' mdxType="SplitSection">
      <p>{`SEP is your partner in the solar energy space. From finding the best solar system for your home to partnering with the most trusted installers, our team at SEP will take you each step of the way to find a solar solution designed to meet your needs.`}</p>
      <p>{`We find success in helping our customers transition to sustainable energy with a plan that fits their needs and their budget.`}</p>
      <Button to="/explore-solar" mdxType="Button">Get Started</Button>
    </SplitSection>
    <SplitSection position='left' img='img2' backgroundText='Culture' title='Solar Lifestyle' mdxType="SplitSection">
      <p>{`Unlike many door-to-door companies, SEP focuses on creating a professional career path for our team members. ﻿Our focus is on curating an environment that enables our team members to develop long-term sales skills, become educated in financial management and investment strategies, and build a sustainable lifestyle year-round.`}</p>
      <Button to="/careers" mdxType="Button">LEARN MORE</Button>
    </SplitSection>
    <Blog mdxType="Blog" />
    <Contact mdxType="Contact" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      